import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { search } from '../../lib/api';

// @ts-ignore
export const FilterContext = createContext();

export const baseFilterCategory = 'wein';
export const defaultSort = 'selling--desc';

export const allCategories = [
  baseFilterCategory,
  'paeckli',
  'allerlei',
  'events',
  'blog',
  'collection',
  'weingueter',
];

const FilterContextProvider = ({ children, defaultResults = [] }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const router = useRouter();

  const [state, setState] = useState({
    loading: router.query.q ? true : false,
    filter: {},
    resultCount: defaultResults.length,
    results: defaultResults,
    sort: defaultSort,
    q: undefined,
    aggregations: {},
    filterUsed: false,
  });

  useEffect(() => {
    const nextState = {};
    if (router.query.q && state.q !== router.query.q) {
      nextState.q = router.query.q;
    }

    if (router.query.sort && state.sort !== router.query.sort) {
      nextState.sort = router.query.sort;
    }

    if (router.query.filter && state.filterUsed === false) {
      nextState.filter = router.query.filter
        // @ts-ignore
        .split('||')
        .reduce((acc, value) => {
          try {
            const splits = value.split('--');
            const attr = splits[0];

            let label =
              value.indexOf('f-t') > -1 || splits.at(-1) === 'true'
                ? attr
                : splits.at(-1);

            acc[encodeURIComponent(value)] = {
              label,
              value,
            };
          } catch (error) {
            // ignore
          }

          return acc;
        }, {});
    }

    if (Object.keys(nextState).length) {
      applyFilters({ ...state, ...nextState, loading: true });
    }
  }, [router.query.q, router.query.sort, router.query.filter]);

  const getQuery = (nextState = undefined) => {
    nextState = nextState || state;
    const queries = [];
    if (Object.keys(nextState.filter).length) {
      queries.push(
        'filter=' +
          Object.keys(nextState.filter)
            .map((k) =>
              nextState.filter[k]?.value ? nextState.filter[k].value : k,
            )
            .join('||'),
      );
    }

    if (nextState.q) {
      queries.push(`q=${nextState.q}`);
      if (nextState.sort.indexOf('selling') === -1) {
        queries.push(`sort=${nextState.sort}`);
      }
    } else {
      queries.push(`sort=${nextState.sort}`);
    }

    if (nextState.q && !Object.keys(nextState.filter).length) {
      queries.push(`c=${allCategories.join('||')}`);
    } else {
      queries.push(`c=${baseFilterCategory}`);
    }

    return queries.join('&');
  };

  const getAggregations = () => {
    search(getQuery() + '&size=0').then((data) => {
      setState({
        ...state,
        aggregations: data,
      });
    });
  };

  const applyFilters = (nextState = undefined) => {
    nextState = nextState || state;
    const query = getQuery(nextState);

    // TODO: load results without reloading page
    const newUrl =
      router.asPath.split('?')[0] + '' + (query ? '?' + query : '');

    window.history.replaceState(
      { ...window.history.state, as: newUrl, url: newUrl },
      '',
      newUrl,
    );

    search(query).then((data) => {
      setState({
        ...nextState,
        results: data.resultCount
          ? data.resultsCategories?.indexOf(baseFilterCategory) > -1 &&
            data.resultsCategories.length == 1
            ? data.results[baseFilterCategory].results
            : data.results
          : [],
        resultCount: data.resultCount,
        loading: false,
        filterUsed: true,
      });
    });
  };

  const closeFilterDialog = (apply = true) => {
    if (apply) {
      applyFilters();
    }
    setDialogVisible(false);
  };

  const showFilterDialog = () => {
    setDialogVisible(true);
  };

  const addFilter = ({
    filter,
    label,
    value,
    updateImmediately,
    single = false,
  }) => {
    if (single) {
      state.filter = { [filter]: { label, value } };
    } else {
      state.filter[filter] = { label, value };
    }
    setState({
      ...state,
      loading: true,
    });
    if (updateImmediately) {
      applyFilters();
    } else {
      getAggregations();
    }
  };

  const removeFilter = ({ filter, updateImmediately }) => {
    if (filter in state.filter) {
      delete state.filter[filter];
      setState({
        ...state,
        loading: true,
      });
      if (updateImmediately) {
        applyFilters();
      } else {
        getAggregations();
      }
    }
  };

  const resetFilters = ({ updateImmediately = false }) => {
    state.filter = {};
    state.q = undefined;
    setState({
      ...state,
      loading: true,
    });
    if (updateImmediately) {
      applyFilters();
    }
  };

  const setSort = (sort) => {
    state.sort = sort;
    setState(state);
    applyFilters();
  };

  const removeSearch = ({ updateImmediately = false }) => {
    state.q = undefined;
    setState({
      ...state,
      loading: true,
    });
    if (updateImmediately) {
      applyFilters();
    }
  };

  return (
    <FilterContext.Provider
      value={{
        removeSearch,
        state,
        applyFilters,
        closeFilterDialog,
        showFilterDialog,
        dialogVisible,
        addFilter,
        removeFilter,
        resetFilters,
        setSort,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
export default FilterContextProvider;

export const useFilter = () => useContext(FilterContext);
